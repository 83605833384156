import {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from '@mui/material/colors';

export function WasteTypes(wasteType) {
  var wasteTypeName = '';
  var primaryColor = deepPurple['A100'];
  var secondaryColor = deepPurple['A200'];
  var textColor = deepPurple['A700'];

  if (wasteType === 'AEO') {
    wasteTypeName = 'Bathroom Aerosols';
    primaryColor = cyan['A100'];
    secondaryColor = cyan['A200'];
    textColor = cyan['A700'];
  } else if (wasteType === 'ANA') {
    wasteTypeName = 'Anatomical';
    primaryColor = pink[50];
    secondaryColor = pink[100];
    textColor = pink[700];
  } else if (wasteType === 'BTD') {
    wasteTypeName = 'Bioreactor Ti Tree Dry';
    primaryColor = lime['A100'];
    secondaryColor = lime['A200'];
    textColor = lime['A700'];
  } else if (wasteType === 'BTW') {
    wasteTypeName = 'Bioreactor Ti Tree Wet';
    primaryColor = lightGreen['A100'];
    secondaryColor = lightGreen['A200'];
    textColor = lightGreen['A700'];
  } else if (wasteType === 'BWD') {
    wasteTypeName = 'Bioreactor Woodlawn Dry';
    primaryColor = green['A100'];
    secondaryColor = green['A200'];
    textColor = green['A700'];
  } else if (wasteType === 'BWW') {
    wasteTypeName = 'Bioreactor Woodlawn Wet';
    primaryColor = teal['A100'];
    secondaryColor = teal['A200'];
    textColor = teal['A700'];
  } else if (wasteType === 'CB') {
    wasteTypeName = 'Cardboard Paper';
    primaryColor = amber[50];
    secondaryColor = amber[100];
    textColor = amber[700];
  } else if (wasteType === 'CLI') {
    wasteTypeName = 'Clinical';
    primaryColor = cyan[50];
    secondaryColor = cyan[100];
    textColor = cyan[700];
  } else if (wasteType === 'COM') {
    wasteTypeName = 'Commingle';
    primaryColor = deepPurple[50];
    secondaryColor = deepPurple[100];
    textColor = deepPurple[700];
  } else if (wasteType === 'CON') {
    wasteTypeName = 'Construction';
    primaryColor = orange['A100'];
    secondaryColor = orange['A200'];
    textColor = orange['A700'];
  } else if (wasteType === 'CYT') {
    wasteTypeName = 'Cytotoxic';
    primaryColor = yellow[50];
    secondaryColor = yellow[100];
    textColor = yellow[700];
  } else if (wasteType === 'EWA') {
    wasteTypeName = 'Ewaste';
    primaryColor = lime[50];
    secondaryColor = lime[100];
    textColor = lime[700];
  } else if (wasteType === 'FLU') {
    wasteTypeName = 'Fluorescent Tube Recycling';
    primaryColor = indigo[50];
    secondaryColor = indigo[100];
    textColor = indigo[700];
  } else if (wasteType === 'GLA') {
    wasteTypeName = 'Glass Recycling';
    primaryColor = teal[50];
    secondaryColor = teal[100];
    textColor = teal[700];
  } else if (wasteType === 'GRE') {
    wasteTypeName = 'Green Recycling';
    primaryColor = green[50];
    secondaryColor = green[100];
    textColor = green[700];
  } else if (wasteType === 'GT') {
    wasteTypeName = 'Grease Trap';
    primaryColor = orange[50];
    secondaryColor = orange[100];
    textColor = orange[700];
  } else if (wasteType === 'GWD') {
    wasteTypeName = 'General Waste Dry';
    primaryColor = red[50];
    secondaryColor = red[100];
    textColor = red[700];
  } else if (wasteType === 'GWW') {
    wasteTypeName = 'General Waste Wet';
    primaryColor = red[50];
    secondaryColor = red[100];
    textColor = red[700];
  } else if (wasteType === 'HAR') {
    wasteTypeName = 'Harvest';
    primaryColor = lightGreen[50];
    secondaryColor = lightGreen[100];
    textColor = lightGreen[700];
  } else if (wasteType === 'LDPE') {
    wasteTypeName = 'LDPE Soft';
    primaryColor = purple[50];
    secondaryColor = purple[100];
    textColor = purple[700];
  } else if (wasteType === 'MET') {
    wasteTypeName = 'Metal Recycling';
    primaryColor = blueGrey[50];
    secondaryColor = blueGrey[100];
    textColor = blueGrey[700];
  } else if (wasteType === 'ORG') {
    wasteTypeName = 'Organics';
    primaryColor = deepOrange[50];
    secondaryColor = deepOrange[100];
    textColor = deepOrange[700];
  } else if (wasteType === 'PAP') {
    wasteTypeName = 'Office Paper';
    primaryColor = amber['A100'];
    secondaryColor = amber['A200'];
    textColor = amber['A700'];
  } else if (wasteType === 'PEF') {
    wasteTypeName = 'Processed Engineered Fuel';
    primaryColor = yellow['A100'];
    secondaryColor = yellow['A200'];
    textColor = yellow['A700'];
  } else if (wasteType === 'PHA') {
    wasteTypeName = 'Pharmaceutical';
    primaryColor = pink['A100'];
    secondaryColor = pink['A200'];
    textColor = pink['A700'];
  } else if (wasteType === 'POL') {
    wasteTypeName = 'Polystyrene';
    primaryColor = purple['A100'];
    secondaryColor = purple['A200'];
    textColor = purple['A700'];
  } else if (wasteType === 'SAN') {
    wasteTypeName = 'Sanitary';
    primaryColor = blue[50];
    secondaryColor = blue[100];
    textColor = blue[700];
  } else if (wasteType === 'SBU') {
    wasteTypeName = 'Secure Burial';
    primaryColor = deepOrange['A100'];
    secondaryColor = deepOrange['A200'];
    textColor = deepOrange['A700'];
  } else if (wasteType === 'SPD') {
    wasteTypeName = 'Secure Paper Destruction';
    primaryColor = lightBlue[200];
    secondaryColor = lightBlue[300];
    textColor = lightBlue[700];
  } else if (wasteType === 'SHRP') {
    wasteTypeName = 'Sharps';
    primaryColor = yellow[200];
    secondaryColor = yellow[300];
    textColor = yellow[700];
  } else if (wasteType === 'TIM') {
    wasteTypeName = 'Timber Recycling';
    primaryColor = brown['A100'];
    secondaryColor = brown['A200'];
    textColor = brown['A700'];
  } else if (wasteType === 'TPR') {
    wasteTypeName = 'Timber Pallet Recycling';
    primaryColor = brown[50];
    secondaryColor = brown[100];
    textColor = brown[700];
  } else {
    wasteTypeName = 'Unknown Waste Type';
    primaryColor = brown[50];
    secondaryColor = brown[100];
    textColor = brown[700];
  }

  return {
    code: wasteType,
    name: wasteTypeName,
    primaryColor: primaryColor,
    secondaryColor: secondaryColor,
    textColor: textColor,
  };
}
