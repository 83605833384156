// Components
import { Box, Button, CircularProgress, Collapse, Divider, Icon, IconButton, Tooltip, Typography } from '@mui/material';

// Utilities
import { useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import { history } from '../utilities/history';
import { IsDesktop } from '../utilities/mediaQuery';
import { signOut } from 'firebase/auth';
import { auth } from '../utilities/firebaseController';
import { useAuthState } from 'react-firebase-hooks/auth';

// Colors
import { green, grey } from '@mui/material/colors';
import {
  accent800,
  accent900,
  primary500,
  background500,
  background600,
  secondary500,
  secondary900,
  background800,
  primary900,
} from '../utilities/colors';

// Assets
import part1 from '../assets/customLogo/1.svg';
import part2 from '../assets/customLogo/2.svg';
import part3 from '../assets/customLogo/3.svg';

// Icons
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ServicesIcon from '@mui/icons-material/LocalShipping';
import ContactUsIcon from '@mui/icons-material/SupportAgent';
import WhatToRecycleIcon from '@mui/icons-material/Help';
import AccountIcon from '@mui/icons-material/AccountCircle';
import OptionsIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import AdminIcon from '@mui/icons-material/AdminPanelSettings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LogOutIcon from '@mui/icons-material/Logout';
import OpenInNew from '@mui/icons-material/OpenInNew';
import Billings from '@mui/icons-material/Payments';

export default function Drawer(props) {
  // STYLES
  // General
  var isDesktop = IsDesktop();
  const flex = {
    display: 'flex',
  };
  const flexGrow = { flexGrow: 1 };

  // Specific
  // Main Container
  const mainContainer = {
    pr: isDesktop ? '15px' : 0,
    width: isDesktop ? '350px' : '100vw',
    maxWidth: isDesktop ? '350px' : '100vw',
    height: isDesktop ? '100vh' : 'fit-content',
    background: isDesktop
      ? `repeating-linear-gradient(
      45deg,
      ${background500},
      ${background500} 12px,
      ${background600} 12px,
      ${background600} 24px)`
      : grey[200],
    boxShadow: isDesktop ? `inset -3px 0 6px ${grey[900]}` : null,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    position: isDesktop ? null : 'fixed',
    bottom: isDesktop ? null : 0,
    zIndex: 2,
  };
  // Nameplate holder desktop
  const nameplateHolderDesktop = {
    p: '30px 15px 30px 20px',
    my: '15px',
    background: grey[200],
    boxShadow: `inset 5px 0 ${accent900}, 0 1px 2px ${grey[900]}`,
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all 0.15s ease',
    '&:hover': {
      boxShadow: `inset 10px 0 ${accent900}, 0 1px 5px ${grey[900]}`,
      '&> .MuiBox-root:nth-of-type(1)': {
        boxShadow: `0 2px 8px -2px ${grey[900]}`,
      },
    },
  };
  // Nameplate desktop
  const nameplateDesktop = {
    ml: '15px',
    display: 'flex',
    flexDirection: 'column',
  };
  // Nameplate holder mobile
  const nameplateHolderMobile = { display: 'flex', justifyContent: 'center' };
  // Nameplate mobile
  const nameplateMobile = {
    py: '10px',
    pr: '15px',
    pl: '60px',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  // Name 1
  const name1 = { fontWeight: 400, color: primary500 };
  // Name 2
  const name2 = { fontWeight: 500, color: accent800 };
  // Tagline
  const tagline = { color: grey[600] };
  // Navigator mobile
  const navigatorMobile = {
    px: '10px',
    ml: '5px',
    background: grey[300],
    color: grey[400],
    borderRadius: '100vw',
  };
  // Drawer profile desktop
  const drawerProfileDesktop = {
    mb: '15px',
    background: accent800,
    boxShadow: `inset 5px 0 ${accent900}, 0 1px 2px ${grey[900]}`,
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    '&> .MuiBox-root': {
      p: '10px 25px 10px 25px',
      color: grey[50],
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '&> :nth-child(2)': {
        ml: '25px',
      },
      '&> .MuiIconButton-root:nth-of-type(1)': {
        transition: 'all 0.15s ease',
        transform: 'scale(1) translateX(0px)',
      },
    },
    transition: 'all 0.15s ease',
    '&:hover': {
      boxShadow: `inset 10px 0 ${accent900}, 0 1px 5px ${grey[900]}`,
      '&> .MuiBox-root': {
        '&> .MuiIconButton-root:nth-of-type(1)': {
          transform: 'scale(1.15) translateX(2px)',
        },
      },
    },
  };
  // Drawer profile options dekstop
  const drawerProfileOptionsDesktop = {
    background: accent900,
    borderBottomRightRadius: '5px',
    boxShadow: `0 1px 2px ${grey[900]}`,
    '&> .MuiBox-root': {
      py: '10px',
      px: '50px',
      color: grey[50],
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      '&> .MuiTypography-root:nth-of-type(1)': {
        transition: 'all 0.15s ease',
      },
      '&:hover': {
        '&> .MuiTypography-root:nth-of-type(1)': {
          ml: '5px',
        },
      },
    },
    '&> .MuiDivider-root': {
      ml: '30px',
      borderBottomColor: accent800,
    },
  };
  // Drawer desktop
  const drawerDesktop = {
    ml: 0,
    mb: '15px',
    // p: '0 5px 0 5px',
    flexGrow: 1,
    background: secondary500,
    color: grey[50],
    boxShadow: `inset 5px 0 ${accent900}, 0px 1px 2px ${grey[900]}`,
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    '&> .MuiBox-root': {
      px: '20px',
      py: '10px',
      color: grey[50],
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      '&> .MuiTypography-root:nth-of-type(1)': {
        ml: '25px',
      },
      '&> .MuiIconButton-root:nth-of-type(1)': {
        transition: 'all 0.15s ease',
        transform: 'scale(1) translateX(0px)',
      },
      '&:hover': {
        '&> .MuiIconButton-root:nth-of-type(1)': {
          transform: 'scale(1.15) translateX(2px)',
        },
      },
    },
    transition: 'all 0.15s ease',
    '&:hover': {
      boxShadow: `inset 10px 0 ${accent900}, 0 1px 5px ${grey[900]}`,
    },
  };
  // Drawer mobile
  const drawerMobile = {
    mx: '15px',
    mb: '10px',
    py: '5px',
    flexGrow: 1,
    background: grey[50],
    borderRadius: '5px',
    borderStyle: 'solid',
    borderColor: grey[300],
    borderWidth: '1px',
    display: 'flex',
    justifyContent: 'space-around',
    '&> .MuiBox-root': {
      color: grey[500],
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      '&> .MuiTypography-root': {
        display: 'none',
      },
    },
  };
  // Drawer desktop others
  const drawerDesktopOthers = {
    ml: 0,
    mb: '15px',
    p: '0 5px 0 5px',
    background: secondary900,
    color: grey[50],
    boxShadow: `inset 5px 0 ${accent900}, 0px 1px 2px ${grey[900]}`,
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    '&> .MuiBox-root': {
      px: '20px',
      py: '10px',
      color: grey[50],
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      '&>:nth-child(2)': {
        ml: '25px',
      },
      '&> .MuiIconButton-root:nth-of-type(1)': {
        transition: 'all 0.15s ease',
        transform: 'scale(1) translateX(0px)',
      },
      '&:hover': {
        '&> .MuiIconButton-root:nth-of-type(1)': {
          transform: 'scale(1.15) translateX(2px)',
        },
      },
    },
    transition: 'all 0.15s ease',
    '&:hover': {
      boxShadow: `inset 10px 0 ${accent900}, 0 1px 5px ${grey[900]}`,
    },
  };
  // Drawer mobile others title
  const drawerMobileOthersTitle = {
    py: '10px',
    pr: '10px',
    pl: '20px',
    background: grey[400],
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };
  // Drawer mobile others
  const drawerMobileOthers = {
    ml: 0,
    background: grey[300],
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    '&> .MuiBox-root': {
      px: '20px',
      py: '10px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      '&>:nth-child(2)': {
        ml: '25px',
      },
    },
  };

  // VARIABLES
  const { isAdmin, billings } = props;
  // Options
  const [optionsPaneState, setOptionsPaneState] = useState(false);
  const [profilePaneState, setProfilePaneState] = useState(false);
  const [showBillings, setShowBillings] = useState(false);

  const currentPage = history.location.pathname;

  const drawerListItems = [
    {
      url: '/home',
      label: 'Home',
      icon: <HomeIcon />,
    },
    {
      url: '/dashboard',
      label: 'Dashboard',
      icon: <DashboardIcon />,
    },
    {
      url: '/services',
      label: 'Services',
      icon: <ServicesIcon />,
    },
  ];

  const [user, loading, error] = useAuthState(auth);

  // FUNCTIONS

  return (
    <Box sx={mainContainer}>
      {/*Desktop items*/}
      <MediaQuery minWidth={480}>
        <Box sx={nameplateHolderDesktop} onClick={() => { }}>
          <Box
            sx={{
              padding: '10px',
              borderRadius: '5px',
              height: '48px',
              width: '48px',
              position: 'relative',
              boxShadow: `0 1px 2px 0px ${grey[900]}`,
              background: grey[50],
              transition: 'all 0.15s ease',
            }}
          >
            <img src={part1} style={{ height: '48px', position: 'absolute' }} />
            <img src={part2} style={{ height: '48px', position: 'absolute' }} />
            <img src={part3} style={{ height: '48px', position: 'absolute' }} />
          </Box>
          <Box sx={nameplateDesktop}>
            <Box sx={flex}>
              <Typography noWrap variant="h4" sx={name1}>
                Waster
              </Typography>
              <Typography noWrap variant="h4" sx={name2}>
                APP
              </Typography>
            </Box>
            <Typography noWrap variant="caption" sx={tagline}>
              Your Waster.com.au Portal
            </Typography>
          </Box>
        </Box>
      </MediaQuery>

      {/*Additional drawer buttons for mobile */}
      <MediaQuery maxWidth={479}>
        <Collapse in={optionsPaneState}>
          <Box sx={drawerMobileOthersTitle}>
            <Typography noWrap>More options</Typography>
            <IconButton
              onClick={() => {
                setOptionsPaneState(false);
              }}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={drawerMobileOthers}>
            <Box
              onClick={() => {
                window.open(
                  'https://waster.com.au/waste-and-recycling-directory/'
                );
              }}
            >
              <IconButton size="small">
                <WhatToRecycleIcon />
              </IconButton>
              <Typography noWrap>What to recycle</Typography>
            </Box>
            <Divider />
            <Box
              onClick={() => {
                history.push('/contact');
                history.go(0);
              }}
            >
              <IconButton size="small">
                <ContactUsIcon />
              </IconButton>
              <Typography noWrap>Contact Us</Typography>
            </Box>
            {user && <Divider />}
            {user && (
              <Box
                onClick={() => {
                  signOut(auth)
                    .then(() => {
                      history.push('/login');
                      history.go(0);
                    })
                    .catch((err) => {
                      console.log(err.code);
                      console.log(err.message);
                    });
                }}
              >
                <IconButton size="small">
                  <LogOutIcon />
                </IconButton>
                <Typography noWrap>Log out</Typography>
              </Box>
            )}
          </Box>
        </Collapse>
      </MediaQuery>

      {/* Billing */}
      {user && (
        <MediaQuery minWidth={480}>
          <Box sx={{
            mb: '15px',
            background: grey[200],
            borderTopRightRadius: '5px',
            borderBottomRightRadius: '5px',
            boxShadow: `inset 5px 0 ${accent900}, 0 1px 5px ${grey[900]}`,
          }}>
            <Box sx={{
              p: '20px 30px',
              gap: '10px',
              display: 'flex',
              flexDirection: 'column',
            }}>
              <Typography
                noWrap
                sx={{
                  color: grey[700],
                }}
              >Current Balance:</Typography>
              {billings && (
                <Box sx={{
                  gap: '10px',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'stretch',
                }}>
                  {billings.map((billing) => {
                    let totalInvoice = 0;
                    billing.invoices.forEach((invoice) => {
                      totalInvoice += invoice.amount
                    })
                    return (
                      <Box
                        sx={{
                          p: '10px',
                          background: grey[100],
                          borderRadius: '5px',
                          borderStyle: 'solid',
                          borderWidth: '1px',
                          borderColor: grey[300],
                          position: 'relative',
                        }}>
                        <Typography
                          variant='subtitle1'
                          noWrap
                          sx={{
                            color: primary900,
                            fontWeight: 700,
                          }}
                        >{billing.name}</Typography>
                        <Typography variant='h4' sx={{
                          color: totalInvoice > 0 ? accent900 : green[500],
                          fontWeight: 700,
                        }}>${totalInvoice}</Typography>
                        <Tooltip
                          title="Open billing in new tab"
                          placement='top-start'
                          disableInteractive
                          arrow
                          onClick={() => {
                            window.open(billing.link)
                          }}
                        >
                          <OpenInNew sx={{
                            position: 'absolute',
                            color: grey[500],
                            right: '15px',
                            bottom: '15px',
                          }} />
                        </Tooltip>
                      </Box>
                    )
                  })}
                </Box>
              )}
              {!billings && (
                <Box sx={{
                  p: '15px',
                  background: grey[50],
                  borderRadius: '5px',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderColor: grey[300],
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <CircularProgress />
                </Box>
              )}
            </Box>
          </Box>
        </MediaQuery>
      )}

      {user && (
        <MediaQuery maxWidth={479}>
          <Collapse in={showBillings}>
            <Box sx={{ p: '15px', gap: '10px', display: 'flex', flexDirection: 'column', background: grey[100] }}>
              <Typography variant="caption" sx={{ color: grey[700] }}>Current Balance:</Typography>
              {billings && (
                <Box sx={{
                  gap: '15px',
                  display: 'flex',
                  flexDirection: 'column',
                  '&> .MuiBox-root': {
                    p: '15px',
                    background: grey[50],
                    borderRadius: '5px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: grey[300],
                    position: 'relative'
                  }
                }}>
                  {billings.map((billing) => {
                    let totalInvoice = 0;
                    billing.invoices.forEach((invoice) => {
                      totalInvoice += invoice.amount
                    })
                    return (
                      <Box
                        onClick={() => {
                          window.open(billing.link)
                        }}>
                        <Typography noWrap fontWeight={500} sx={{ color: primary900 }}>{billing.name}</Typography>
                        <Typography variant='h5' fontWeight={700} sx={{ color: totalInvoice > 0 ? accent900 : green[500] }}>${totalInvoice}</Typography>
                        <Tooltip
                          title="Open billing in new tab"
                          placement='top-start'
                          disableInteractive
                          arrow
                          onClick={() => {
                            window.open(billing.link)
                          }}
                        >
                          <OpenInNew sx={{
                            position: 'absolute',
                            color: grey[500],
                            right: '15px',
                            bottom: '15px',
                          }} />
                        </Tooltip>
                      </Box>
                    )
                  })}
                </Box>
              )}
              {!billings && (
                <Box sx={{
                  p: '15px',
                  background: grey[50],
                  borderRadius: '5px',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderColor: grey[300],
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <CircularProgress />
                </Box>
              )}
            </Box>
          </Collapse>
        </MediaQuery>
      )}

      {/*Desktop profile options */}
      <MediaQuery minWidth={480}>
        <Box
          onMouseEnter={() => {
            setProfilePaneState(true);
          }}
          onMouseLeave={() => {
            setProfilePaneState(false);
          }}
          sx={drawerProfileDesktop}
        >
          <Box>
            <Box sx={{ gap: '25px', display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={() => { }} size="small" color="inherit">
                <AccountIcon />
              </IconButton>
              <Typography noWrap sx={{ ...flexGrow, maxWidth: '15ch' }}>
                Account
              </Typography>
            </Box>
            <IconButton size="small" color="inherit">
              {profilePaneState ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
          <Collapse in={profilePaneState}>
            <Box sx={drawerProfileOptionsDesktop}>
              {!user && (
                <Box
                  onClick={() => {
                    history.push('/register');
                    history.go(0);
                  }}
                >
                  <Typography noWrap>Register</Typography>
                </Box>
              )}
              {!user && <Divider />}
              {!user && (
                <Box
                  onClick={() => {
                    history.push('/login');
                    history.go(0);
                  }}
                >
                  <Typography noWrap>Log in</Typography>
                </Box>
              )}
              {!user && <Divider />}
              {user && (
                <Box
                  onClick={() => {
                    history.push('/profile');
                    history.go(0);
                  }}
                >
                  <Typography noWrap>View Profile</Typography>
                </Box>
              )}
              {user && <Divider />}
              {user && (
                <Box
                  onClick={() => {
                    signOut(auth)
                      .then(() => {
                        history.push('/login');
                        history.go(0);
                      })
                      .catch((err) => {
                        console.log(err.code);
                        console.log(err.message);
                      });
                  }}
                >
                  <Typography noWrap>Log out</Typography>
                </Box>
              )}
            </Box>
          </Collapse>
        </Box>
      </MediaQuery>

      {/*Mobile items*/}
      <MediaQuery maxWidth={479}>
        <Box sx={nameplateHolderMobile}>
          <Box sx={nameplateMobile}>
            <Typography noWrap sx={name1}>
              Waster
            </Typography>
            <Typography noWrap sx={name2}>
              APP
            </Typography>
            <Box sx={navigatorMobile}>
              <Typography variant="body2" noWrap sx={{ fontWeight: 500 }}>
                {currentPage}
              </Typography>
            </Box>
          </Box>
          <IconButton
            onClick={() => {
              setOptionsPaneState(!optionsPaneState);
            }}
          >
            <OptionsIcon />
          </IconButton>
        </Box>
      </MediaQuery>

      {/* Drawer list items */}
      <Box sx={isDesktop ? drawerDesktop : drawerMobile}>
        {drawerListItems.map((drawerItem, index) => (
          <Box
            key={index}
            onClick={() => {
              history.push(drawerItem.url);
              history.go(0);
            }}
          >
            <IconButton
              size="small"
              color="inherit"
              sx={{
                color:
                  currentPage === drawerItem.url
                    ? isDesktop
                      ? null
                      : accent800
                    : null,
              }}
            >
              {drawerItem.icon}
            </IconButton>
            <Typography noWrap>{drawerItem.label}</Typography>
          </Box>
        ))}
        {user && billings && (
          <MediaQuery minWidth={480}>
            <Box
              onMouseEnter={() => {
                setShowBillings(true)
              }}
              onMouseLeave={() => {
                setShowBillings(false)
              }}
            >
              <IconButton
                size="small"
                color="inherit"
              >
                <Billings />
              </IconButton>
              <Typography noWrap>Billings & Invoices</Typography>
            </Box>
            <Collapse in={showBillings}
              onMouseEnter={() => {
                setShowBillings(true)
              }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', background: secondary900, }}>
                {billings.map((billing, index) => (
                  <Box
                    key={billing.name}
                    sx={{
                      boxShadow: `inset 10px 0 ${accent900}`,
                      '&:hover': {
                        cursor: 'pointer',
                        boxShadow: `inset 15px 0 ${accent900}`
                      }
                    }}
                    onClick={() => {
                      window.open(billing.link)
                    }}
                  >
                    <Typography noWrap sx={{ py: '15px', pl: '30px' }}>
                      {billing.name}
                    </Typography>
                    {index + 1 < billings.length && (
                      <Divider sx={{ ml: '5px', borderColor: secondary500 }} />
                    )}
                  </Box>
                ))}
              </Box>
            </Collapse>
          </MediaQuery>
        )}
        {user && billings && (
          <MediaQuery maxWidth={479}>
            <Box
              onClick={() => { setShowBillings(!showBillings) }}
            >
              <IconButton
                size="small"
                color="inherit"
                sx={{
                  color: showBillings ? accent800 : null,
                }}
              >
                <Billings />
              </IconButton>
              <Typography noWrap>Billings and Invoice</Typography>
            </Box>
          </MediaQuery>
        )}
        {
          isAdmin && (
            <Box
              onClick={() => {
                history.push('/admin');
                history.go(0);
              }}
            >
              <IconButton
                size="small"
                color="inherit"
                sx={{
                  color:
                    currentPage === '/admin'
                      ? isDesktop
                        ? null
                        : accent800
                      : null,
                }}
              >
                <AdminIcon />
              </IconButton>
              <Typography noWrap>Administrator</Typography>
            </Box>
          )
        }
        {/* Profile button for mobile */}
        <MediaQuery maxWidth={479}>
          <Box
            onClick={() => {
              history.push('/profile');
              history.go(0);
            }}
          >
            <IconButton
              size="small"
              color="inherit"
              sx={{
                color: currentPage === '/profile' ? accent800 : null,
              }}
            >
              <AccountIcon />
            </IconButton>
            <Typography noWrap>Profile</Typography>
          </Box>
        </MediaQuery>
      </Box >

      {/* Additional drawer buttons for desktop */}
      < MediaQuery minWidth={480} >
        <Box sx={drawerDesktopOthers}>
          <Box
            onClick={() => {
              window.open(
                'https://waster.com.au/waste-and-recycling-directory/'
              );
            }}
          >
            <IconButton size="small" color="inherit">
              <WhatToRecycleIcon />
            </IconButton>
            <Typography noWrap>What to recycle</Typography>
          </Box>
          <Box
            onClick={() => {
              history.push('/contact');
              history.go(0);
            }}
          >
            <IconButton size="small" color="inherit">
              <ContactUsIcon />
            </IconButton>
            <Typography noWrap>Contact us</Typography>
          </Box>
        </Box>
      </MediaQuery >
    </Box >
  );
}
