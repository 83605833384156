// Components
import { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import Drawer from './components/drawer';

// Pages
import PageNotFound from './pages/404';
import Home from './pages/home';
import Dashboard from './pages/dashboard';
import Services from './pages/services';
import Contact from './pages/contact';
import Login from './pages/login';
import Register from './pages/register';
import Profile from './pages/profile';

import Admin from './pages/admin';
// import Maintenance from './pages/maintenance';

// Router control
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Utilities
import { IsDesktop } from './utilities/mediaQuery';
import { history } from './utilities/history';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './utilities/firebaseController';
import axios from 'axios';

// Colors
import { background700, background800 } from './utilities/colors';
import { grey } from '@mui/material/colors';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import Notifications from './components/notifications';
import { WasteTypes } from './utilities/wasteTypes';


const simplypaidRegularLink = 'https://simplypaid.com.au/Ezypayhub/directlaunch?tkn=A9treLeQLm9RS6OEcFxyRDF0G25ch8oZ9jfe6vwQH_GXWLapl-5L5wJDcBZ9kFSc-S3QPo-SoaINoiSy9BM009Bs3iLV9TsFFJCsoTIWolIifJ6jjx4Q2DwgDEIcwWh8c1j27bnn1OYdjN0O39BqmA&auth='
const simplypaidDebitLink = 'https://simplypaid.com.au/Ezypayhub/directlaunch?tkn=XcxvMJ_BFEaWRKXP2_57TSV6QpR4kXhpIQGaI643j6Jy33We16tQn1zcWxgK5xZhI-8Q477H02WBIZaKDxyTgLBU-ZgfBwPpSM_u4Iz-oMNjJ5li69lRHnO6HjHHf3vxBaGzOfk80MruS8XIqH9d6Q&auth='


export default function App() {
  // Maintenance
  // if (history.location.pathname !== '/maintenance') {
  //   history.push('maintenance');
  //   history.go(0);
  // }
  // STYLES
  // Generic
  var isDesktop = IsDesktop();

  // VARIABLES
  const [user, loading, error] = useAuthState(auth);
  const [userVerified, setUserVerified] = useState(null);
  const [wastedgeDetails, setWastedgeDetails] = useState(null);
  const [wastedgeServices, setWastedgeServices] = useState(null);
  const [wastedgeSchedules, setWastedgeSchedules] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [billings, setBillings] = useState(null)
  const [billingsLinks, setBillingsLinks] = useState([]);
  const [notificationsPanel, setNotificationsPanel] = useState(null);


  // FUNCTIONS
  const getDetails = (email) => {
    axios
      .get(
        `https://us-central1-wasterapp-9ea2a.cloudfunctions.net/wastedge/getDetails/${email}`
      )
      .then((response) => {
        setWastedgeDetails(response.data.result);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 500) {
          setTimeout(() => {
            getDetails(email);
          }, 6000);
        }
      });
  };
  const getServices = (id) => {
    axios
      .get(
        `https://us-central1-wasterapp-9ea2a.cloudfunctions.net/wastedge/getServices/${id}`
      )
      .then((response) => {
        let resultTemp = []
        for (var i = 0; i < response.data.result.length; i++) {
          if (WasteTypes(response.data.result[i].waste_type).name !== "Unknown Waste Type") {
            resultTemp.push(response.data.result[i])
          }
        }
        console.log(resultTemp)
        setWastedgeServices(resultTemp);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 500) {
          setTimeout(() => {
            getServices(id);
          }, 6000);
        }
      });
  };
  const getSchedules = (id) => {
    axios
      .get(
        `https://us-central1-wasterapp-9ea2a.cloudfunctions.net/wastedge/getSchedules/${id}`
      )
      .then((response) => {
        setWastedgeSchedules(response.data.result);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 500) {
          setTimeout(() => {
            getSchedules(id);
          }, 6000);
        }
      });
  };
  const getIsAdmin = (email) => {
    axios
      .get(
        `https://us-central1-wasterapp-9ea2a.cloudfunctions.net/wastedge/isAdmin/${email}`
      )
      .then((response) => {
        setIsAdmin(response.data.isAdmin);
      })
      .catch((error) => {
        console.log(error);
        setTimeout(() => {
          getIsAdmin(email);
        }, 6000);
      });
  };
  const getBillings = (details) => {
    let billingTemp = []
    console.log("DETAILS: ", details)
    details.forEach((detail, index) => {
      let id = detail.id;
      let name = detail.name
      let type = detail.type
      let link = detail.link
      axios
        .get(
          `https://us-central1-wasterapp-9ea2a.cloudfunctions.net/wastedgev2/getBilling/${type}/${id}`
        )
        .then((response) => {
          billingTemp[index] = ({ id, name, link, ...response.data })
        })
        .catch((error) => {
          console.log(error);
        });
    })
    console.log("BILLING: ", billingTemp)
    setBillings(billingTemp)
  };
  // EFFECTS
  if (history.location.pathname === '/') {
    history.push('/dashboard');
    history.go(0);
  }
  useEffect(() => {
    if (user) {
      getIsAdmin(user.email);
    }
  }, [user, loading, error]);
  useEffect(() => {
    if (user) {
      setUserVerified(user.emailVerified);
    }
  }, [user, loading, error]);
  useEffect(() => {
    if (userVerified) {
      getDetails(user.email);
    }
  }, [userVerified]);
  useEffect(() => {
    if (wastedgeDetails) {
      let detailsTemp = []
      wastedgeDetails.forEach((detail) => {
        let id = detail.$id;
        let name = detail.name;
        let type = detail.accounting_vendor_id === 'DIRECT DEBIT' ? 'debit' : 'regular';
        let link = "";
        if (type === "debit") {
          link = `${simplypaidDebitLink}${id}`;
        }
        else if (type === "regular") {
          link = `${simplypaidRegularLink}${id}`;
        }
        detailsTemp.push({ id, name, type, link });
      })
      getBillings(detailsTemp)
    }
  }, [wastedgeDetails])

  useEffect(() => {
    setInterval(() => {
      setNotificationsPanel(<Notifications />);
    }, 1000);
  }, []);

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        background: `repeating-linear-gradient(
          45deg,
          ${background700},
          ${background700} 12px,
          ${background800} 12px,
          ${background800} 24px)`,
        display: 'flex',
        flexDirection: isDesktop ? 'row' : 'column-reverse',
        overflow: 'auto',
        position: 'relative',
      }}
    >
      {notificationsPanel}
      <Drawer isAdmin={isAdmin} billings={billings} user={user} />
      <Box
        sx={{
          mb: isDesktop ? 0 : '100px',
          height: '100vh',
          flexGrow: 1,
          display: 'flex',
          overflowY: 'auto',
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path={'home'} element={<Home />} />
            <Route path={'dashboard'} element={<Dashboard />} />
            <Route path={'services'} element={<Services />} />
            <Route path={'contact'} element={<Contact />} />
            <Route path={'login'} element={<Login />} />
            <Route path={'register'} element={<Register />} />
            <Route path={'profile'} element={<Profile />} />
            <Route path={'admin'} element={<Admin />} />
            {/* Maintenance */}
            {/* <Route path={'maintenance'} element={<Maintenance />} /> */}
            <Route path={'*'} element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </Box>
    </Box>
  );
}
