// Components
import { useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';

// Colors
import { accent900, accent800 } from '../utilities/colors';

// Utilities
import moment from 'moment';
import { RouteCycles } from '../utilities/routeCycles';

// Icons
import LeftArrowIcon from '@mui/icons-material/ChevronLeft';
import RightArrowIcon from '@mui/icons-material/ChevronRight';
import { grey } from '@mui/material/colors';
import { WasteTypes } from '../utilities/wasteTypes';
import { IsDesktop } from '../utilities/mediaQuery';

// Global Variables
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
export const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
var schedulesList = [];

export default function Calendar(props) {
  // STYLES
  // Generic
  var isDesktop = IsDesktop();
  // VARIABLES
  const { openModal, services, schedules, setReqService, reqService } = props;

  const currentDate = moment().format('L');
  const [selectedYear, setSelectedYear] = useState(
    moment(currentDate, 'MM/DD/YYYY').year()
  );
  const [selectedMonth, setSelectedMonth] = useState(
    moment(currentDate, 'MM/DD/YYYY').month() + 1
  );
  const [firstDayOfMonth, setFirstDayOfMonth] = useState(
    moment(`${selectedMonth}/01/${selectedYear}`, 'MM/DD/YYYY').day()
  );
  const [numOfDays, setNumOfDays] = useState(
    moment(`${selectedMonth}/${selectedYear}`, 'MM/YYYY').daysInMonth()
  );
  const [calendarDays, setCalendarDays] = useState([
    getCalendarDays(firstDayOfMonth, selectedMonth, selectedYear),
  ]);

  // FUNCTIONS
  const decSelectedYear = () => {
    setSelectedYear(selectedYear - 1);
  };
  const incSelectedYear = () => {
    setSelectedYear(selectedYear + 1);
  };
  const decSelectedMonth = () => {
    if (selectedMonth - 1 === 0) {
      setSelectedMonth(12);
      decSelectedYear();
    } else {
      setSelectedMonth(selectedMonth - 1);
    }
  };
  const incSelectedMonth = () => {
    if (selectedMonth + 1 === 13) {
      setSelectedMonth(1);
      incSelectedYear();
    } else {
      setSelectedMonth(selectedMonth + 1);
    }
  };
  function getCalendarDays(day, month, year) {
    let firstDay = moment(`${month}/01/${year}`, 'MM/DD/YYYY')
      .subtract(day, 'days')
      .format('L');
    let rows = [];
    for (let i = 0; i < 6; i++) {
      let column = [];
      for (let j = 0; j < 7; j++) {
        let scheduledServicesTemp = [];
        if (services) {
          for (let service of services) {
            if (
              schedulesList.includes(
                `${moment(firstDay, 'MM/DD/YYYY')
                  .add(i * 7 + j, 'days')
                  .format('L')}-${service.waste_type}`
              )
            ) {
              scheduledServicesTemp.push(service.waste_type);
            }
          }
        }
        column.push({
          scheduledServicesLength: scheduledServicesTemp.length,
          scheduledServices: scheduledServicesTemp,
          fullDate: moment(firstDay, 'MM/DD/YYYY')
            .add(i * 7 + j, 'days')
            .format('L'),
          date: moment(firstDay, 'MM/DD/YYYY')
            .add(i * 7 + j, 'days')
            .format('D'),
          currentDay:
            moment(firstDay, 'MM/DD/YYYY')
              .add(i * 7 + j, 'days')
              .format('L') === currentDate
              ? true
              : false,
          currentMonth:
            moment(firstDay, 'MM/DD/YYYY')
              .add(i * 7 + j, 'days')
              .format('MM') ===
              moment(`${month}/01/${year}`, 'MM/DD/YYYY').format('MM')
              ? true
              : false,
        });
      }
      rows.push(column);
    }
    return rows;
  }

  useEffect(() => {
    setFirstDayOfMonth(
      moment(`${selectedMonth}/01/${selectedYear}`, 'MM/DD/YYYY').day()
    );
    setNumOfDays(
      moment(`${selectedMonth}/${selectedYear}`, 'MM/YYYY').daysInMonth()
    );
  }, [services, schedules, selectedMonth, selectedYear]);

  useEffect(() => {
    setCalendarDays(
      getCalendarDays(firstDayOfMonth, selectedMonth, selectedYear)
    );
  }, [firstDayOfMonth, numOfDays, schedulesList]);

  useEffect(() => {
    var addDate = null;
    var subtractDate = null;
    var schedulesTemp = null;
    var serviceTemp = null;

    if (schedules && services) {
      var schedulesListTemp = [];
      for (var i = 0; i < services.length; i++) {
        serviceTemp = services[i];
        for (var j = 0; j < schedules.length; j++) {
          schedulesTemp = schedules[j];
          if (schedulesTemp.service === serviceTemp.$id) {
            if (serviceTemp.route_cycle !== 'RI') {
              for (var k = 0; true; k++) {
                addDate = moment(schedulesTemp.next_service_date)
                  .add(
                    RouteCycles(serviceTemp.route_cycle).forEvery * k,
                    RouteCycles(serviceTemp.route_cycle).unit
                  )
                  .format('L');
                if (
                  moment(addDate).format('YYYY') >
                  moment(selectedYear, 'YYYY').format('YYYY')
                ) {
                  break;
                }
                schedulesListTemp.push(addDate + '-' + serviceTemp.waste_type);
              }
              for (var l = 1; true; l++) {
                subtractDate = moment(schedulesTemp.next_service_date)
                  .subtract(
                    RouteCycles(serviceTemp.route_cycle).forEvery * l,
                    RouteCycles(serviceTemp.route_cycle).unit
                  )
                  .format('L');
                if (
                  moment(subtractDate).format('YYYY') <
                  moment(selectedYear, 'YYYY').format('YYYY')
                ) {
                  break;
                }
                schedulesListTemp.push(
                  subtractDate + '-' + serviceTemp.waste_type
                );
              }
            }
          }
        }
      }
      schedulesList = [...schedulesList, ...schedulesListTemp];
    }
  }, [schedules, services, selectedYear]);

  return (
    <Box
      sx={{
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&> .MuiButton-root': {
            color: grey[600],
            '&:hover': {
              color: grey[800],
            },
          },
        }}
      >
        <Button
          size="small"
          onClick={decSelectedYear}
          startIcon={<LeftArrowIcon />}
        >
          {selectedYear - 1}
        </Button>
        <Typography variant="h6">{selectedYear}</Typography>
        <Button
          size="small"
          onClick={incSelectedYear}
          endIcon={<RightArrowIcon />}
        >
          {selectedYear + 1}
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&> .MuiButton-root': {
            color: grey[600],
            '&:hover': {
              color: accent800,
            },
          },
        }}
      >
        {isDesktop ? (
          <Button onClick={decSelectedMonth} startIcon={<LeftArrowIcon />}>
            {moment(
              selectedMonth - 1 === 0 ? 12 : selectedMonth - 1,
              'M'
            ).format('MMMM')}
          </Button>
        ) : (
          <IconButton onClick={decSelectedMonth} size="small">
            <LeftArrowIcon />
          </IconButton>
        )}
        <Typography variant="h4" sx={{ flexGrow: 1, textAlign: 'center' }}>
          {moment(selectedMonth, 'M').format('MMMM')}
        </Typography>
        {isDesktop ? (
          <Button onClick={incSelectedMonth} endIcon={<RightArrowIcon />}>
            {moment(
              selectedMonth + 1 === 13 ? 1 : selectedMonth + 1,
              'M'
            ).format('MMMM')}
          </Button>
        ) : (
          <IconButton onClick={incSelectedMonth} size="small">
            <RightArrowIcon />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          mb: '15px',
          gap: '15px',
          display: 'flex',
        }}
      >
        {days.map((day) => (
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              key={day}
              variant="caption"
              sx={{ width: '3ch', color: grey[700] }}
            >
              {day}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          gap: '15px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {calendarDays.map((row, rowIndex) => (
          <Box
            key={rowIndex}
            sx={{
              gap: '15px',
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            {row.map((col, colIndex) => (
              <LightTooltip
                title={
                  col.currentMonth ? (
                    <Box sx={{ px: '5px', py: '10px' }}>
                      <Typography variant="caption">
                        {moment(col.fullDate).format('ll')}
                      </Typography>
                      {col.currentDay ? (
                        <Typography variant="h5">Today</Typography>
                      ) : null}
                      {col.scheduledServicesLength > 0 ? (
                        col.scheduledServices.map((scheduledService) => (
                          <Typography
                            key={scheduledService}
                            sx={{
                              color: WasteTypes(scheduledService).textColor,
                              borderRadius: '5px',
                            }}
                          >
                            {WasteTypes(scheduledService).name}
                          </Typography>
                        ))
                      ) : (
                        <Typography variant="body2">
                          No scheduled service. Click to request extra pick-up.
                        </Typography>
                      )}
                    </Box>
                  ) : (
                    ''
                  )
                }
              >
                <Badge
                  badgeContent={
                    col.currentMonth ? col.scheduledServicesLength < 2
                      ? null
                      : col.scheduledServicesLength : null
                  }
                  color="primary"
                  sx={{ flexGrow: 1, display: 'flex' }}
                >
                  <Box
                    onClick={() => {
                      if (setReqService && reqService) {
                        setReqService(reqService);
                      }
                      openModal(moment(col.fullDate).format('MM/DD/YYYY'));
                    }}
                    sx={{
                      p: '5px',
                      background: col.currentMonth
                        ? col.scheduledServicesLength > 0
                          ? `repeating-linear-gradient(45deg, ${WasteTypes(col.scheduledServices[0]).primaryColor
                          } 0px, ${WasteTypes(col.scheduledServices[0]).primaryColor
                          } 15px, ${WasteTypes(col.scheduledServices[0])
                            .secondaryColor
                          } 15px, ${WasteTypes(col.scheduledServices[0])
                            .secondaryColor
                          } 30px)`
                          : grey[100]
                        : grey[200],
                      color: col.currentMonth
                        ? col.currentDay
                          ? accent800
                          : grey[900]
                        : grey[400],
                      borderRadius: '5px',
                      boxShadow: col.currentMonth
                        ? col.currentDay
                          ? `inset 0 0 0 3px ${accent900}, 0 1px 2px ${grey[900]}`
                          : `0 1px 2px ${grey[900]}`
                        : null,
                      aspectRatio: '1/1',
                      flexGrow: 1,
                      display: 'flex',
                    }}
                  >
                    <Typography
                      key={colIndex}
                      sx={{
                        width: '2ch',
                        textAlign: 'center',
                      }}
                    >
                      {col.date}
                    </Typography>
                  </Box>
                </Badge>
              </LightTooltip>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
